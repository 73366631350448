

#tsparticles {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}

.react-toggle-track {
  background-color:#000000!important;
  width:60px!important;
}

.react-toggle--checked .react-toggle-track {
  background-color:#ffffff!important;
  width:60px!important;
}

.react-toggle--checked .react-toggle-thumb {
 left:36px!important; 
}

.react-toggle-track-check {
  width:17px!important;
  top: -6px!important;
  left: 8px!important;
}

.react-toggle-track-x {
  width:16px!important;
  top: -7px!important;
  right: 8px!important;
}
.react-toggle--checked .react-toggle-thumb {
  border-color: #000000!important;
}